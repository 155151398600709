<template>
  <div className="animated fadeIn">

      <div class="row mb-2">
        <div class="col-10">
          <h2 class="pb-0 mb-0">{{ Insurer }} Rate Tables</h2>
        </div>
        <div class="col-2">
          <b-button variant="outline-light btn-sm"  class="float-right" :to="{ name: 'RatesInsurers', params: { product: $route.params.product } }">Back</b-button>
        </div>
      </div>

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="active" slot-scope="props">
            <i v-if="props.row.active === true || props.row.active === 'true'" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>

          <template slot="actions" slot-scope="props">
            <b-button variant="secondary" :to="{ name: 'TableRates', params: { product: $route.params.product, insurer: $route.params.insurer, id: props.row.id } }">View</b-button>

            <button @click="downloadCSV(props.row)" class="btn btn-md btn-outline-dark ml-2" >Export</button>
          </template>

      </v-server-table>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'DataTable',
    components: {
      ServerTable,
    },
    data: function () {
      return {
        product_name: null,
        insurer_name: null,
        get_document: false,
        columns: [
          /*'id',*/ 'name', "table_type", 'actions'
        ],
        data: [],
        options: {
          requestFunction: (data) => {
              return axios.get('/tables/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer, {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            console.log(resp.data.table_types)
            this.options.listColumns.table_type = resp.data.table_types
            return {
              data: data.data.data,
              count: data.data.pagination.total
            }
          },
          headings: {
            name: 'Table Name',
            table_type: "Route Type"
          },
          filterByColumn: true,
          listColumns: {
            table_type: [
              {id: 1, text: 'Buildings'},
              {id: 2, text: 'Contents'},
              {id: 3, text: 'Unspecified'},
              {id: 4, text: 'Specified'},
              {id: 5, text: 'Pedal Cycle'}
            ],
          },
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      selectAll() {
           let length = this.tableData.length
           this.isRead = !this.isSelected;

           for (let i = 0; i < length; i++) {
               this.tableData[i].isChecked=this.isSelected;
           }
      },
      getStatus (status) {
        return status === null ? 'success' : status === 1 ? 'warning' : status === 'Banned' ? 'danger' : 'primary'
      },
      getPolicies() {
        axios.get('/tables/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer).then(
          response => {
            this.data = response.data
            this.data.options.listColumns.table_type = response.data.table_types
          }
        ).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

        return this.data
      },
      downloadCSV(table_data) {

        var confirmed = confirm("Download Table Data\n\nAre you sure you wish to download?");
        if (confirmed === true) {
          this.get_document = true;
          axios({
            url: '/tables/' + table_data.id +'/export/',
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            console.log(response)
            console.log(response.file_name)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', table_data.name + ".csv");
            document.body.appendChild(link);
            link.click();
            this.get_document = false;
          }).catch(error => {

                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('post error');
                console.log(error.response);
            });
        }

      },
    },
    computed: {
      Insurer() {
        let insurers = JSON.parse(localStorage.getItem('insurers', []))

        console.log(insurers)

        for (const insurer of insurers) {
          if (insurer.id === this.$route.params.insurer) {
            return insurer.name
          }
        }
        return ''
      }
    },
    mounted() {
      //this.getPolicies()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getPolicies()
        );

      }
    }
  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
